import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import VideoSection from "../../../../src/components/sections/videoSection";
import ExternalLink from "../../../../src/tools/ExternalLink.js";
import * as React from 'react';
export default {
  useIntl,
  FormattedMessage,
  GatsbyImage,
  getImage,
  VideoSection,
  ExternalLink,
  React
};
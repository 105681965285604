// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-mdx": () => import("./../../../src/pages/contacts.mdx" /* webpackChunkName: "component---src-pages-contacts-mdx" */),
  "component---src-pages-final-product-mdx": () => import("./../../../src/pages/final-product.mdx" /* webpackChunkName: "component---src-pages-final-product-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-slug-js": () => import("./../../../src/pages/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-mdx-slug-js" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-product-elaboration-mdx": () => import("./../../../src/pages/product-elaboration.mdx" /* webpackChunkName: "component---src-pages-product-elaboration-mdx" */),
  "component---src-pages-terms-mdx": () => import("./../../../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */)
}

